.font-link {
  font-family: 'Roboto Condensed', sans-serif;
}
/* Above is Offical SLU font stuff (DEV NOTE: copy and past the font-family into whatever class you want the font used in) */
/* Below is mobile overlay */
.AppM {
  text-align: center;
}

.AppM-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px);
  /* font-size: calc(10px + 2vmin); */
  color: black;
}

#overlay {
  font-family: 'Roboto Condensed', sans-serif;
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  z-index: 2;
  cursor: pointer;
}

#overlay2 {
  font-family: 'Roboto Condensed', sans-serif;
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  z-index: 2;
  cursor: pointer;
}

#AppForm {
  font-family: 'Roboto Condensed', sans-serif;
  display: block;
}

/* above is for the mobile overlay */

#FIX{
  padding-bottom: 2%;
}

#Smoll{
  width: fit-content;
}

.App {
  text-align: center;
}

Form{
    /* min-width: 800px; */
    /* min-height: 10566px; */
    max-width: 1500px;
    max-height: 10566px;
    /* width: 696px;
    height: 10566px; */
    /* padding-bottom: 5%; */
    /* display:flex; */
}



table{
  border:1px solid black;
  table-layout: fixed;
  width: 100%;  
  font-size: small;
}

td,th {
  border:1px solid black;
  font-size: small;
}

#VSmoll{
  width: 7%;
}

#Smoll{
  /* table-layout: fixed; */
  width: 7%;
}

#breakfree{
  border:1px solid black;
  /* table-layout: fixed; */
  width: 100%;  
  font-size: small;
  border:1px solid black;
  font-size: small;
}

.App-logo {
  align-items: center;
  pointer-events: none;
}



.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 5%;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-Formy {
  background-color: white;
  /* min-height: 100vh; */
  display: inline-block;
  flex-direction: column;
  /* padding-left: 5%;
  padding-right: 5%; */
  padding: 5%;
  
  justify-content: center;
  
  font-size: 15pt;
  color: black;
}

.buttony {
  background-image: url('../../Photo/btn_google_signin_dark_normal_web.png');
  background-size:cover;
  width: 191px;
  height: 46px;
}

.Cards{
  padding-top: 5%;
  padding-bottom: 5%;
}

.card{
  padding-bottom: 5%;
  display:flex;
}

.cardy {
  width: 561px;
  /* height: 300px; */
}

.buttonyt {
  width: 186px;
  height: 44px;
}

.App-Form-bod {
  text-align: left;
  background-color: white;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  /* padding-left: 5%;
  padding-right: 5%; */
  padding: 5%;
  
  
  font-size: 15pt;
  color: black;
}

.Buttoney {
  width: 25%;
}

.App-Form .Form {
  padding: 10pt;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

[type="date"] {
  background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* custom styles */

body {
  padding: 4em;
  background: #e5e5e5;
  /* font: 13px/1.4 Geneva, 'Lucida Sans', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif; */
}
/* body {
  padding: 4em;
  background: #e5e5e5;
  font: 13px/1.4 Geneva, 'Lucida Sans', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif;
} */
label {
  display: block;
}
input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
  /* width: 190px; */
}

textarea{
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
  /* width: 190px; */
}

.wrapper
{
  padding-bottom: 90px;
}

.divider
{
  position: relative;
  margin-top: 90px;
  height: 1px;
}

.div-transparent:before
{
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
}

.div-arrow-down:after
{
  content: "";
  position: absolute;
  z-index: 1;
  top: -7px;
  left: calc(50% - 7px);
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
  background-color: white;
  border-bottom: 1px solid rgb(48,49,51);
  border-right: 1px solid rgb(48,49,51);
}

.div-tab-down:after
{
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: calc(50% - 10px);
  width: 20px;
  height: 14px;
  background-color: white;
  border-bottom: 1px solid rgb(48,49,51);
  border-left: 1px solid rgb(48,49,51);
  border-right: 1px solid rgb(48,49,51);
  border-radius: 0 0 8px 8px;
}

.div-stopper:after
{
  content: "";
  position: absolute;
  z-index: 1;
  top: -6px;
  left: calc(50% - 7px);
  width: 14px;
  height: 12px;
  background-color: white;
  border-left: 1px solid rgb(48,49,51);
  border-right: 1px solid rgb(48,49,51);
}

.div-dot:after
{
  content: "";
  position: absolute;
  z-index: 1;
  top: -9px;
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
  background-color: goldenrod;
  border: 1px solid rgb(48,49,51);
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px white,
          0 0 0 4px white;
}


